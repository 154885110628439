<template>
<div class="wrapper-out">
  <div class="wrapper">
      <div class="wrapper_input">
          <label class="form-list-icon">手机号</label>
          <input v-model="phone" class="wrapper_content" type="text" placeholder="请输入手机号" />
          <span class="code" @click="getCode">获取验证码</span>
      </div>
      <div class="wrapper_input">
          <label class="form-list-icon">验证码</label>
          <input v-model="code" class="wrapper_content" type="text" placeholder="请输入密码" />
      </div>
      <div class="wrapper_input">
          <label class="form-list-icon">密码</label>
          <input v-model="password" class="wrapper_content" type="password" placeholder="请输入密码（长度6-12位）" />
      </div>
      <div class="wrapper_input">
          <label class="form-list-icon">推荐码</label>
          <input v-model="referralCode" class="wrapper_content" placeholder="请输入推荐码（非必填）" />
      </div>
      <div class="wrapper_login_button" @click="onRegister">确 认</div>
      <div class="wrapper_login_link" @click="goLogin">已有账号？立即登录</div>
      <Toast v-if="show" :message="toastMessage"/>
  </div>
</div>
</template>
<script>
import qs from 'qs'
import { useRouter } from 'vue-router'
import { reactive, toRefs } from 'vue'
import { post, ajaxParam } from '../../units/request.js'
import { Toast } from 'vant'
export default {
  components: { Toast },
  name: 'Register',
  setup () {
    const router = useRouter()
    const data = reactive({ phone: '', password: '', code: '', referralCode: '' })
    const getCode = async () => {
      if (data.phone.length <= 0) {
        Toast('请输入手机号')
        return
      }
      var regNum = new RegExp('^1[3-9][0-9]{9}$')
      if (!regNum.test(data.phone)) {
        Toast('请输入正确的手机号')
        return
      }
      const param = {
        phone: data.phone,
        type: 0
      }
      ajaxParam(param)
      const res = await post('v1/sms/sendSmsCode.json', qs.stringify(param))
      if (res.data.code === '606' || res.data.code === '200') {
        Toast(res.data.msg)
        return false
      }
    }
    const onRegister = async () => {
      if (data.phone.length <= 0) {
        Toast('请输入手机号')
        return
      }
      var regNum = new RegExp('^1[3-9][0-9]{9}$')
      if (!regNum.test(data.phone)) {
        Toast('请输入正确的手机号')
        return
      }
      if (data.code.length <= 0) {
        Toast('请输入验证码')
        return
      }
      if (data.password.length <= 0) {
        Toast('请输入密码')
        return
      }
      if (data.password.length < 6 || data.password.length > 12) {
        Toast('密码长度不合法，至少6-12个字符')
        return
      }
      const param = {
        phone: data.phone,
        userName: data.phone,
        password: data.password,
        code: data.code,
        referralCode: data.referralCode
      }
      ajaxParam(param)
      const res = await post('v1/user/register.json', qs.stringify(param))
      if (res.data.code === '605' || res.data.code === '800') {
        Toast(res.data.msg)
        return false
      }
      if (res.data.code === '200') {
        Toast('您已注册成功')
        setTimeout(res => {
          router.push({ name: 'Login' })
        }, 2000)
      }
    }
    const goLogin = () => {
      router.push({ name: 'Login' })
    }
    const { phone, password, code, referralCode } = toRefs(data)
    return {
      phone, password, code, referralCode, getCode, onRegister, goLogin
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-out{
  position: absolute;
  left: 0;
  right:0;
  top:0;
  bottom: 0;
  background:#fff
}
.wrapper{
    position: absolute;
    left:0;
    right: 0;
    top: .2rem;
    .wrapper_input{
        height: 0.68rem;
        padding: 0 .16rem;
        box-sizing: border-box;
        margin: 0 .4rem ;
        border-bottom: 1px solid rgba(0,0,0,.1);
        position: relative;
        .form-list-icon{
          display: block;
          width: .7rem;
          height: .68rem;
          line-height: .68rem;
          position: absolute;
          left:0;
          top: 0;
          }
          .code {
              width: 0.85rem;
              height: 0.26rem;
              border:1px solid #2ac560;
              color:#2ac560;
              text-align: center;
              line-height: 0.26rem;
              border-radius: .04rem;
              position: absolute;
              right:0;
              top:50%;
              margin-top: -0.13rem
          }
    }
    .wrapper_content{
        position:absolute;
        left: .7rem;
        right: .2rem;
        line-height: .68rem;
        border:none;
        outline: none;
        background:none;
        color: #777;
        &::placeholder {
            color: #777;
        }
    }
    .wrapper_login_button{
        height: .48rem;
        margin:.42rem .4rem .26rem .4rem;
        background:#52da5f;
        box-shadow: 0 .04rem .08rem 0 rgba(0,145,255,0.32);
        border-radius: .25rem;
        color:#fff;
        font-size: .16rem;
        line-height: .48rem;
        text-align: center;
    }
    .wrapper_login_link{

        font-size: .14rem;
        text-align: center;
        color: #777;
    }
}
</style>
